import { FormProvider } from "react-hook-form";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import iso3166 from "iso-3166-1-alpha-2";
import { MediaQueries } from "utils/MediaQueries";
import { useAppSelector } from "stores/redux/hooks/useSelector";
import { useCreateRegistrantMutation, useGetRegistrantQuery, useUpdateRegistrantMutation } from "features/Domains/api/registrantsApi";
import { CreateRegistrantSchema, CreateRegistrantSchemaType } from "features/Domains/api/commands/CreateRegistrantSchema";
import { useAppForm } from "hooks/useAppForm";
import { UpdateRegistrantType } from "features/Domains/api/commands/UpdateRegistrantSchema";
import FilledButton from "components/Buttons/FilledButton/FilledButton";
import MaterialSymbol from "components/MaterialSymbol/MaterialSymbol";
import { DialogPortal } from "hooks/DialogPortal";
import Typo from "components/Typo/Typo";
import FormOutlinedTextField from "components/Form/components/FormOutlinedTextField";
import TextButton from "components/Buttons/TextButton/TextButton";
import FormMenu from "components/Form/components/FormMenu";

const RegistrantsDialog: React.FC = () => {
    const baseClassName = "registrant-dialog"

    const [showDialog, setShowDialog] = useState<boolean>(false)

    const customerId = useAppSelector(state => state.auth.userInfo?.profile.customerId)

    const navigate = useNavigate()

    const { id } = useParams<{ id: string }>()

    const { data } = useGetRegistrantQuery({
        customerId: customerId ?? "",
        registrantId: id ?? ""
    }, { skip: !id || !customerId })

    const initData: CreateRegistrantSchemaType = useMemo(() => {
        return {
            customerId: customerId,
            id: id,
            alias: data?.alias ? data.alias : "",
            companyName: data?.companyName ? data.companyName : "",
            jobTitle: data?.jobTitle ? data.jobTitle : "",
            firstName: data?.firstName ? data.firstName : "",
            lastName: data?.lastName ? data.lastName : "",
            address: {
                street: data?.address.street ? data.address.street : "",
                houseNumber: data?.address.houseNumber ? data.address.houseNumber : "",
                suffix: data?.address.suffix ? data.address.suffix : "",
                postalCode: data?.address.postalCode ? data.address.postalCode : "",
                city: data?.address.city ? data.address.city : "",
                state: data?.address.state ? data.address.state : "",
                country: data?.address.country ? data.address.country : ""
            },
            phone: data?.phone ? data.phone : "",
            fax: data?.fax ? data.fax : "",
            email: data?.email ? data.email : "",
        }
    }, [customerId, data, id])

    const {
        form,
        formReset,
        onFormSubmit,
        isLoading,
        isSuccess
    } = useAppForm(
        initData,
        id ? UpdateRegistrantType : CreateRegistrantSchema,
        id ? useUpdateRegistrantMutation : useCreateRegistrantMutation
    )

    const onClickStore = () => setShowDialog(true)

    const onCloseDialog = useCallback(() => {
        formReset()
        setShowDialog(false)
    }, [formReset, setShowDialog])

    useEffect(() => {
        if (isSuccess) {
            onCloseDialog()
            if (!id && data) navigate(`/registrants/${data?.id}`)
        }
    }, [id, data, isSuccess, onCloseDialog, navigate])

    return (
        <>
            <div className={`${baseClassName}__create-button-container`}>
                <FilledButton textType={"icon-text"}
                    color={"blue"}
                    className={`${baseClassName}__create-button`}
                    onClick={onClickStore}>
                    <MaterialSymbol name={id ? "edit" : "add"} /> {id ? "Edit" : "Create"}
                </FilledButton>
            </div>

            <DialogPortal
                breakpoint={"medium"}
                type={"basic"}
                show={showDialog}
                className={`${baseClassName}`}
                headline={"Contact info"}
                onCloseClick={onCloseDialog}
                isLoading={isLoading}
                content={(
                    <FormProvider {...form}>
                        <form onSubmit={onFormSubmit}>
                            <div className={`${baseClassName}__form`}>
                                {
                                    !MediaQueries.medium() && <Typo.Headline size={"small"} className={`${baseClassName}__title`}>
                                        Create registrant
                                    </Typo.Headline>
                                }

                                {
                                    !id &&
                                    <FormOutlinedTextField
                                        label={"Alias"}
                                        name={"alias"}
                                        isLoading={isLoading}
                                    />
                                }

                                <div className={`${baseClassName}__line`}>
                                    {
                                        !id &&
                                        <FormOutlinedTextField
                                            label={"Company (optional)"}
                                            name={"companyName"}
                                            isLoading={isLoading}
                                        />
                                    }

                                    <FormOutlinedTextField
                                        label={"Job title (optional)"}
                                        name={"jobTitle"}
                                        isLoading={isLoading}
                                    />
                                </div>

                                <div className={`${baseClassName}__line`}>
                                    <FormOutlinedTextField
                                        label={"First name"}
                                        name={"firstName"}
                                        className={`${baseClassName}__first-name`}
                                        isLoading={isLoading}
                                    />

                                    <FormOutlinedTextField
                                        label={"Last name"}
                                        name={"lastName"}
                                        className={`${baseClassName}__last-name`}
                                        isLoading={isLoading}
                                    />
                                </div>

                                <div className={`${baseClassName}__line`}>
                                    <FormOutlinedTextField
                                        label={"Street"}
                                        name={"street"}
                                        className={`${baseClassName}__street`}
                                        isLoading={isLoading}
                                    />

                                    <FormOutlinedTextField
                                        label={"Number"}
                                        name={"houseNumber"}
                                        className={`${baseClassName}__house-number`}
                                        isLoading={isLoading}
                                    />

                                    <FormOutlinedTextField
                                        label={"Suffix"}
                                        name={"suffix"}
                                        className={`${baseClassName}__suffix`}
                                        isLoading={isLoading}
                                    />
                                </div>

                                <div className={`${baseClassName}__line`}>
                                    <FormOutlinedTextField
                                        label={"Postal code"}
                                        name={"postalCode"}
                                        className={`${baseClassName}__postal-code`}
                                        isLoading={isLoading}
                                    />

                                    <FormOutlinedTextField
                                        label={"City"}
                                        name={"city"}
                                        className={`${baseClassName}__city`}
                                        isLoading={isLoading}
                                    />
                                </div>

                                <FormOutlinedTextField
                                    label={"State"}
                                    name={"state"}
                                    isLoading={isLoading}
                                />

                                <FormMenu<{ label: string; value: string; }, CreateRegistrantSchemaType>
                                    name="country"
                                    label="Country"
                                    labelSelector={v => v.label}
                                    valueSelector={v => v.value}
                                    options={Object.entries(iso3166.getData()).map(([key, value]) => ({
                                        label: value ?? "",
                                        value: key
                                    }))}
                                    isLoading={isLoading}
                                    className={`${baseClassName}__tld`}
                                    closeOnOptionClick
                                />

                                <FormOutlinedTextField
                                    label={"Phone"}
                                    name={"phone"}
                                    isLoading={isLoading}
                                />

                                <FormOutlinedTextField
                                    label={"Fax (optional)"}
                                    name={"fax"}
                                    isLoading={isLoading}
                                />

                                <FormOutlinedTextField
                                    label={"Email"}
                                    name={"email"}
                                    isLoading={isLoading}
                                />

                                <div className={`${baseClassName}__buttons-container`}>
                                    <TextButton color={"blue"}
                                        type={"button"}
                                        textType={"text-only"}
                                        onClick={onCloseDialog}
                                    >
                                        Cancel
                                    </TextButton>

                                    <TextButton color={"blue"}
                                        textType={"text-only"}
                                    >
                                        {id ? "Save" : "Create"}
                                    </TextButton>
                                </div>
                            </div>
                        </form>
                    </FormProvider>
                )}
            />
        </>
    )
}

export default RegistrantsDialog;