import { api } from "stores/redux/reducers/api";

export const featuresApi = api.injectEndpoints({
    endpoints: build => ({
        isFeatureEnabled: build.query<string, string>({
            query: (feature) => `features/${feature}`
        })
    })
})

export const {
    useIsFeatureEnabledQuery
} = featuresApi