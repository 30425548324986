import React, {FC, useMemo, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import RegistrantsDialog from "../../../../components/RegistrantDialog/RegistrantDialog";
import iso3166 from "iso-3166-1-alpha-2"
import DeleteRegistrantDialog from "../../../../components/DeleteRegistrantDialog/DeleteRegistrantDialog";
import { useGetRegistrantsByCustomerIdQuery } from "features/Domains/api/registrantsApi";
import { useAppSelector } from "stores/redux/hooks/useSelector";
import { useThemeContext } from "contexts/ThemeContext";
import Table from "components/Table/Table";
import { Registrant } from "features/Domains/api/responses/Registrant";
import TextButton from "components/Buttons/TextButton/TextButton";
import MaterialSymbol from "components/MaterialSymbol/MaterialSymbol";

const RegistrantsTab: FC = () => {
    const baseClassName = "registrants-tab"

    const {getThemeClassName} = useThemeContext()

    const [searchParams] = useSearchParams()
    
    const navigate = useNavigate()

    const [deleteId, setDeleteId] = useState<string>();

    const pageSize = 10
    const page = useMemo(() => parseInt(searchParams.get("page") ?? "0"), [searchParams])

    const customerId = useAppSelector(state => state.auth.userInfo?.profile.customerId)

    const {
        data,
        isLoading,
        isFetching,
        isError
    } = useGetRegistrantsByCustomerIdQuery({
        customerId: customerId ?? "",
        page: page,
        pageSize: pageSize
    }, {
        skip: !customerId,
    })

    return (
        <div className={`${baseClassName} ${getThemeClassName(baseClassName)}`}>
            <RegistrantsDialog/>
            
            <DeleteRegistrantDialog id={deleteId} setId={setDeleteId}/>

            <Table<Registrant>
                className={`${baseClassName}__column`}
                columns={[
                    {
                        name: "Alias",
                        className: `${baseClassName}__alias`,
                        render: (item) => <p>{item.alias ?? "-"}</p>
                    },
                    {
                        name: "Company",
                        className: `${baseClassName}__company`,
                        render: (item) => <p>{item.companyName ?? "-"}</p>,
                        breakpoint: "medium"
                    },
                    {
                        name: "Title",
                        className: `${baseClassName}__title`,
                        render: (item) => <p>{item.jobTitle ?? "-"}</p>,
                        breakpoint: "medium"
                    },
                    {
                        name: "Name",
                        className: `${baseClassName}__name`,
                        render: (item) => <p>{item.firstName + " " + item.lastName}</p>
                    },
                    {
                        name: "Country",
                        className: `${baseClassName}__country`,
                        render: (item) => <p>{iso3166.getCountry(item.address.country)}</p>,
                        breakpoint: "compact"
                    },
                    {
                        name: "",
                        clickable: false,
                        className: `${baseClassName}__buttons`,
                        render: (item) => {
                            if (item.deletedAt != null) return <></>
                            
                            return (
                                <TextButton color={"red"} onClick={() => setDeleteId(item.id)} textType={"icon-only"} disabled={item.deletedAt != null}>
                                    <MaterialSymbol name={"delete"}/>
                                </TextButton>
                            )
                        },
                        breakpoint: "compact"
                    }
                ]}
                onItemClick={(item) => navigate(`/dns/registrants/${item.id}`)}
                page={page}
                pageSize={pageSize}
                totalItems={data?.totalItems ?? 0}
                isLoading={isLoading}
                isFetching={isFetching}
                isError={isError}
                items={data}
            />
        </div>
    )
}

export default RegistrantsTab