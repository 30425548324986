import {createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo} from "react";
import {Log, User, UserManager, WebStorageStateStore} from "oidc-client-ts"
import {AuthStatus} from "../types/constants/AuthStatus";
import {useAppDispatch} from "../stores/redux/hooks/useAppDispatch";
import {authenticated, authenticationError, refreshed, unauthenticated} from "../features/Auth/slices/authSlice";
import {useAppSelector} from "../stores/redux/hooks/useSelector";
import {useGetCustomerQuery} from "../features/Customers/redux/customersApi";
import {useCustomerId} from "../features/Auth/hooks/useCustomerId";

interface IUserContext {
    redirectToSignIn: (promptLogin?: boolean) => void

    signInCallback(): Promise<User | void>

    signOut(): Promise<void>

    loadUser(): void

    isBillable: boolean
}

const userManager = new UserManager({
    authority: process.env.REACT_APP_OAUTH_URL ?? '',
    client_id: "argo-dashboard",
    redirect_uri: `${process.env.REACT_APP_URL}/oauthRedirect`,
    loadUserInfo: true,
    userStore: new WebStorageStateStore({
        store: window.localStorage
    }),
    scope: "openid profile email offline_access",
    post_logout_redirect_uri: `${process.env.REACT_APP_URL}`,
    automaticSilentRenew: false
})

Log.setLogger(console)

export const UserContext = createContext({} as IUserContext)

export const UserContextProvider: FC<PropsWithChildren> = ({children}) => {
    const dispatch = useAppDispatch()
    const authStatus = useAppSelector(state => state.auth.status)
    const userInfo = useAppSelector(state => state.auth.userInfo)
    const customerId = useCustomerId()
    const {data: customer} = useGetCustomerQuery(
        userInfo?.profile.customerId ?? "",
        {
            skip: !customerId
        }
    )

    const redirectToSignIn = async (promptLogin?: boolean) => {
        try {
            await userManager.signinRedirect({
                prompt: promptLogin ? "login" : undefined
            })
        } catch (e) {
            console.error(e)
        }
    }

    const signInCallback = () => userManager.signinCallback()
    const signOut = () => userManager.signoutRedirect()
    const silentRenew = () => userManager.signinSilent()

    const handleUnauthenticated = () => {
        userManager
            .removeUser()
            .finally(() => dispatch(unauthenticated()))
    }

    const handleUserLoaded = (user: User | null) => {
        if (user)
            dispatch(authenticated({
                profile: {
                    sub: user.profile.sub,
                    customerId: !!user.profile.customer_id ? user.profile.customer_id as string : "",
                    givenName: user.profile.given_name ?? "",
                    familyName: user.profile.family_name ?? "",
                    email: user.profile.email ?? "",
                    isBillable: user.profile.is_billable as boolean ?? ""
                },
                accessToken: user.access_token,
                expiresAt: user.expires_at!
            }))
        else
            handleUnauthenticated()
    }

    const renew = useCallback(() => {
        silentRenew()
            .then(user => {
                dispatch(refreshed({
                    accessToken: user?.access_token!,
                    profile: {
                        sub: user?.profile.sub ?? "",
                        customerId: !!user?.profile.customer_id ? user.profile.customer_id as string : "",
                        givenName: user?.profile.given_name ?? "",
                        familyName: user?.profile.family_name ?? "",
                        email: user?.profile.email ?? "",
                        isBillable: user?.profile.is_billable as boolean ?? ""
                    },
                    expiresAt: user?.expires_at!
                }))
            })
            .catch(() => {
                dispatch(authenticationError())
            })
    }, [dispatch])

    const startSilentRenew = useCallback(() => {
        let timeout: number;

        if (authStatus === AuthStatus.AUTHENTICATED) {
            if (userInfo && userInfo?.expiresAt) {
                let remaining = userInfo?.expiresAt * 1000 - Date.now()

                // Refresh 10 minutes early
                let renewAt = remaining - 10 * 60 * 1000

                if (renewAt <= 0) renewAt = 0

                if (renewAt === 0) {
                    renew()
                } else {
                    timeout = window.setTimeout(() => {
                        renew()
                    }, renewAt)
                }
            }
        }

        return () => {
            if (timeout) clearTimeout(timeout)
        }
    }, [authStatus, renew, userInfo])

    const loadUser = () => {
        userManager
            .getUser()
            .then(handleUserLoaded)
    }

    const isBillable = useMemo(() => {
        if (!customer) return false

        return (
            (
                (customer.useSameInvoiceAddress && customer.address) ||
                (!customer.useSameInvoiceAddress && customer.invoiceAddress)
            )
            && customer.isBankAccountVerified
        ) ?? false;
    }, [customer])

    useEffect(() => {
        let tb: string | number | NodeJS.Timeout | undefined;

        
        if (userInfo && (userInfo.profile.isBillable !== isBillable)) {
            tb = setTimeout(() => renew(), 1000)
        }
        
        return () => {
            if(tb) clearTimeout(tb)
        }
    }, [userInfo, isBillable, renew]);

    useEffect(() => {
        if (authStatus === AuthStatus.UNAUTHENTICATED) redirectToSignIn(false).then()
    }, [authStatus, dispatch]);

    useEffect(() => {
        startSilentRenew()
    }, [startSilentRenew]);

    return (
        <UserContext.Provider value={{
            redirectToSignIn,
            signInCallback,
            signOut,
            loadUser,
            isBillable
        }}>
            {children}
        </UserContext.Provider>
    )
}

export const useUserContext = () => useContext(UserContext)