export interface IRouteDefinition {
    name: string
    path: string
    sidebar: boolean
    feature?: string
    navigationLink?: string
    iconName?: string
    iconType?: "regular" | "outlined" | "round" | "sharp" | "two-tone"
    children?: IRouteDefinition[]
}

interface IRoutingDefinition {
    oauthRedirect: IRouteDefinition

    billing: IRouteDefinition
    invoice: IRouteDefinition

    managedApps: IRouteDefinition
    managedApp: IRouteDefinition
    selectManagedApp: IRouteDefinition
    createManagedApp: IRouteDefinition

    domainNames: IRouteDefinition
    domainName: IRouteDefinition
    registrant: IRouteDefinition

    vdcs: IRouteDefinition
    vdc: IRouteDefinition
    editVdc: IRouteDefinition
    createVdc: IRouteDefinition
}

export const RoutingDefinition: IRoutingDefinition = {
    managedApps: {
        name: "Managed Apps",
        path: "/managedApps",
        navigationLink: "/managedApps?page=0&pageSize=10",
        sidebar: true,
        iconName: "widgets",
        iconType: "outlined"
    },
    managedApp: {
        name: "Managed App",
        path: "/managedApps/:id",
        sidebar: false
    },
    selectManagedApp: {
        name: "Managed Apps",
        path: "/managedApps/select",
        sidebar: false
    },
    createManagedApp: {
        name: "Managed Apps",
        path: "/managedApps/create",
        sidebar: false
    },
    domainNames: {
        name: "Domains",
        path: "/dns/*",
        navigationLink: "/dns",
        sidebar: true,
        iconName: "domain",
        children: [
            {
                name: "Domain names",
                path: "domainNames",
                navigationLink: "/dns/domainNames?page=0&pageSize=10",
                sidebar: false
            },
            {
                name: "Registrants",
                path: "registrants",
                navigationLink: "/dns/registrants?page=0&pageSize=10",
                sidebar: false
            }
        ]
    },
    domainName: {
        name: "Domain",
        path: "/dns/domainNames/:id",
        navigationLink: "/dns/domainNames",
        sidebar: false
    },
    registrant: {
        name: "Registrant",
        path: "/dns/registrants/:id",
        sidebar: false
    },
    vdcs: {
        name: "Private Cloud",
        path: "/vdcs",
        feature: "PrivateCloud",
        sidebar: true,
        navigationLink: "/vdcs?page=0&pageSize=10",
        iconName: "storage"
    },
    vdc: {
        name: "Private Cloud",
        path: "/vdcs/:id",
        sidebar: false
    },
    editVdc: {
        name: "Private Cloud",
        path: "/vdcs/:id/edit",
        sidebar: false
    },
    createVdc: {
        name: "Create Private Cloud",
        path: "/vdcs/create",
        sidebar: false
    },
    billing: {
        name: "Billing",
        path: "/billing/*",
        sidebar: true,
        navigationLink: "/billing?page=0&pageSize=10#invoices",
        iconName: "payments",
        children: [
            {
                name: "Invoices",
                path: "invoices",
                navigationLink: "invoices?page=0&pageSize=10",
                sidebar: false
            },
            {
                name: "Customer Info",
                path: "customer-info",
                navigationLink: "customer-info",
                sidebar: false
            }
        ]
    },
    invoice: {
        name: "Invoice",
        path: "/invoices/:id",
        sidebar: false
    },
    oauthRedirect: {
        name: "OAuth Redirect",
        path: "/oauthRedirect",
        sidebar: false
    }
}