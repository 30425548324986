import {api} from "../../../stores/redux/reducers/api";
import {Paginated} from "../../../types/models/Paginated";
import {ManagedApp} from "../models/ManagedApp";
import {GetManagedAppByCustomerIdRequest} from "../models/GetManagedAppByCustomerIdRequest";
import {CreateManagedAppRequest} from "../models/CreateManagedAppRequest";
import {Result} from "../../../types/models/Result";

const managedAppsApi = api.injectEndpoints({
    endpoints: build => ({
        getManagedAppsByCustomerId: build.query<Paginated<ManagedApp>, GetManagedAppByCustomerIdRequest>({
            query: ({
                        customerId,
                        page,
                        pageSize
                    }) => `customers/${customerId}/managedApps?page=${page}&pageSize=${pageSize}`
        }),
        getManagedApp: build.query<ManagedApp, { customerId: string, managedAppId: string }>({
            query: ({customerId, managedAppId}) => `/customers/${customerId}/managedApps/${managedAppId}`
        }),
        createManagedApp: build.mutation<Result<ManagedApp>, { customerId: string } & CreateManagedAppRequest>({
            query: ({customerId, ...data}) => {
                console.log(customerId)
                return ({
                url: `/customers/${customerId}/managedApps`,
                method: "POST",
                body: {
                    ...data as CreateManagedAppRequest,
                    variables: JSON.stringify(data.variables),
                }
            })},
            invalidatesTags: (data) => data ? [{type: "ManagedApps", id: data.response.id}] : []
        }),
        updateManagedApp: build.mutation<ManagedApp, {
            customerId: string,
            managedAppId: string
        } & CreateManagedAppRequest>({
            query: ({customerId, managedAppId, ...data}) => ({
                url: `/customers/${customerId}/managedApps/${managedAppId}`,
                method: "PUT",
                body: {
                    ...data as CreateManagedAppRequest,
                    variables: JSON.stringify(data.variables),
                }
            }),
            invalidatesTags: (data) => data ? [{type: "ManagedApps", id: data.id}] : []
        })
    })
})

export const {
    useGetManagedAppsByCustomerIdQuery,
    useGetManagedAppQuery,
    useCreateManagedAppMutation,
    useUpdateManagedAppMutation
} = managedAppsApi