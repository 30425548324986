import { z } from 'zod';

export const UpdateRegistrantType = z.object({
    customerId: z.string().optional(),
    id: z.string().optional(),
    alias: z.string().min(1).max(255),
    companyName: z.string().max(255).optional(),
    jobTitle: z.string().max(255),
    firstName: z.string().min(1).max(255),
    lastName: z.string().min(1).max(255),
    address: z.object({
        street: z.string().min(1).max(255),
        houseNumber: z.string(),
        suffix: z.string().max(255).optional(),
        postalCode: z.string().min(1).max(255),
        city: z.string().min(1).max(255),
        state: z.string().min(1).max(255),
        country: z.string().min(1).max(255),
    }),
    phone: z.string().min(1).max(255),
    fax: z.string().max(10).optional(),
    email: z.string().min(1).max(255)
})

export type UpdateRegistrantSchemaType = z.infer<typeof UpdateRegistrantType>