import React, { FC, useEffect, useMemo, useState } from "react";
import {
    UpdateCustomerRequest,
    UpdateCustomerRequestSchema
} from "../../../../../../../../Customers/models/UpdateCustomerRequest";
import Typo from "../../../../../../../../../components/Typo/Typo";
import FormOutlinedTextField from "../../../../../../../../../components/Form/components/FormOutlinedTextField";
import OutlinedTextField from "../../../../../../../../../components/TextFields/OutlinedTextField/OutlinedTextField";
import TextButton from "../../../../../../../../../components/Buttons/TextButton/TextButton";
import { DialogPortal } from "../../../../../../../../../hooks/DialogPortal";
import { useGetCustomerQuery, useUpdateCustomerMutation } from "../../../../../../../../Customers/redux/customersApi";
import { FormProvider } from "react-hook-form";
import FormCheckbox from "../../../../../../../../../components/Form/components/FormCheckbox";
import { CustomerTypes } from "../../../../../../../../Customers/constants/CustomerTypes";
import { useAppForm } from "../../../../../../../../../hooks/useAppForm";
import { useCustomerId } from "../../../../../../../../Auth/hooks/useCustomerId";
import FormMenu from "components/Form/components/FormMenu";

interface IProps {
    showDialog: boolean
    setShowDialog: (show: boolean) => void
}

const CustomerInfoDialog: FC<IProps> = ({ showDialog, setShowDialog }) => {
    const baseClassName = "billing-address-info"

    const customerId = useCustomerId()

    const {
        data: customer
    } = useGetCustomerQuery(
        customerId ?? "",
        {
            skip: !customerId
        }
    )

    const [showShadow, setShowShadow] = useState<boolean>(false)

    const onScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
        const { scrollTop } = event.currentTarget

        if (scrollTop > 5 && !showShadow) setShowShadow(true)
        else if (scrollTop < 5 && showShadow) setShowShadow(false)
    }

    const initData: UpdateCustomerRequest = useMemo(() => {
        if (!customer) return {
            id: customerId ?? "",
            address: {
                street: "",
                houseNumber: "",
                postalCode: "",
                state: "",
                city: ""
            },
            invoiceAddress: undefined,
            useSameInvoiceAddress: false,
            name: "",
            phoneNumber: "",
            type: CustomerTypes.Individual
        } as UpdateCustomerRequest

        return { ...customer } as UpdateCustomerRequest
    }, [customer, customerId])

    const {
        form,
        formReset,
        onFormSubmit,
        isLoading,
        isSuccess,
    } = useAppForm(
        initData,
        UpdateCustomerRequestSchema,
        useUpdateCustomerMutation
    )

    const onCloseDialog = () => {
        setShowDialog(false)
        formReset()
    }

    const useSameInvoiceAddress = form.watch("useSameInvoiceAddress")

    useEffect(() => {
        if (isSuccess) {
            setShowDialog(false)
            formReset()
        }
    }, [isSuccess, setShowDialog, formReset]);

    return (
        <DialogPortal
            breakpoint={"medium"}
            type={"basic"}
            show={showDialog}
            showShadow={showShadow}
            className={`${baseClassName}__dialog`}
            headline={"Contact info"}
            onCloseClick={onCloseDialog}
            isLoading={isLoading}
            content={(
                <FormProvider {...form}>
                    <form onSubmit={onFormSubmit}>
                        <Typo.Headline className={`${baseClassName}__dialog-headline`} size={"small"}>
                            Address info
                        </Typo.Headline>

                        <div className={`${baseClassName}__inputs`} onScroll={onScroll}>
                            <FormMenu<{ label: string, value: string }, UpdateCustomerRequest>
                                name={"type"}
                                label={"Business type"}
                                labelSelector={v => v.label}
                                valueSelector={v => v.value}
                                isLoading={isLoading}
                                closeOnOptionClick
                                options={[
                                    {
                                        label: "Individual",
                                        value: CustomerTypes.Individual
                                    },
                                    { label: "Company", value: CustomerTypes.Company },
                                ]}
                            />

                            <Typo.Title size={"medium"}>Contact</Typo.Title>
                            <FormOutlinedTextField
                                isLoading={isLoading}
                                label={"Company name*"}
                                name={"name"}
                                className={`${baseClassName}__name`}
                                supportingText={"Fill out your own name if you are an individual"}
                            />

                            <FormOutlinedTextField
                                isLoading={isLoading}
                                label={"Phone*"}
                                name={"phoneNumber"}
                                className={`${baseClassName}__phone`}
                            />

                            <Typo.Title size={"medium"}>Address</Typo.Title>
                            <div className={`${baseClassName}__street-line`}>
                                <FormOutlinedTextField isLoading={isLoading}
                                    label={"Street*"}
                                    name={"address.street"}
                                    className={`${baseClassName}__street`} />

                                <div className={`${baseClassName}__house-number`}>
                                    <FormOutlinedTextField isLoading={isLoading}
                                        label={"No.*"}
                                        name={"address.houseNumber"}
                                        className={`${baseClassName}__house-number`} />
                                    <FormOutlinedTextField isLoading={isLoading}
                                        label={"Suffix"}
                                        name={"address.suffix"}
                                        className={`${baseClassName}__suffix`} />
                                </div>
                            </div>

                            <div className={`${baseClassName}__postal-code-city`}>
                                <FormOutlinedTextField isLoading={isLoading}
                                    label={"Postal code*"}
                                    name={"address.postalCode"} />

                                <FormOutlinedTextField isLoading={isLoading}
                                    label={"City*"}
                                    name={"address.city"} />
                            </div>

                            <FormOutlinedTextField isLoading={isLoading}
                                label={"State*"}
                                name={"address.state"} />

                            <OutlinedTextField label={"Country"} value={"Netherlands"} disabled />

                            <Typo.Title size={"medium"}>Invoice address</Typo.Title>

                            <div className={`${baseClassName}__checkbox`}>
                                <FormCheckbox name={"useSameInvoiceAddress"}
                                    isLoading={isLoading}
                                />

                                <Typo.Body>Use same address for invoicing</Typo.Body>
                            </div>

                            <div
                                className={`${baseClassName}__invoice-address ${baseClassName}__invoice-address--${useSameInvoiceAddress ? "close" : "open"}`}>
                                <div className={`${baseClassName}__street-line`}>
                                    <FormOutlinedTextField isLoading={isLoading} label={"Street*"}
                                        name={"invoiceAddress.street"}
                                        className={`${baseClassName}__street`} />

                                    <div className={`${baseClassName}__house-number`}>
                                        <FormOutlinedTextField isLoading={isLoading}
                                            label={"No.*"}
                                            name={"invoiceAddress.houseNumber"}
                                            className={`${baseClassName}__house-number`} />

                                        <FormOutlinedTextField isLoading={isLoading}
                                            label={"Suffix"}
                                            name={"invoiceAddress.suffix"}
                                            className={`${baseClassName}__suffix`} />
                                    </div>
                                </div>

                                <div className={`${baseClassName}__postal-code-city`}>
                                    <FormOutlinedTextField isLoading={isLoading}
                                        label={"Postal code*"}
                                        name={"invoiceAddress.postalCode"} />

                                    <FormOutlinedTextField isLoading={isLoading}
                                        label={"City*"}
                                        name={"invoiceAddress.city"} />
                                </div>
                                <FormOutlinedTextField isLoading={isLoading} label={"State*"}
                                    name={"invoiceAddress.state"} />
                                <OutlinedTextField label={"Country"}
                                    value={"Netherlands"}
                                    disabled />
                            </div>
                        </div>

                        <div className={`${baseClassName}__buttons`}>
                            <TextButton type={"button"}
                                color={"blue"}
                                textType={"text-only"}
                                onClick={onCloseDialog}
                            >
                                Cancel
                            </TextButton>

                            <TextButton type={"submit"}
                                color={"blue"}
                                textType={"text-only"}
                            >
                                Save
                            </TextButton>
                        </div>
                    </form>
                </FormProvider>
            )}
        />
    )
}

export default CustomerInfoDialog