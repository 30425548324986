import React, { FC, useEffect, useMemo, useState } from "react";
import { matchPath, Navigate, Route, Routes as ReactRoutes, useLocation } from "react-router-dom";
import { RoutingDefinition } from "./RoutingDefinition";
import { AnimatePresence } from "framer-motion";
import OAuthRedirect from "../../features/Auth/pages/OAuthRedirect/OAuthRedirect";
import Page from "../../components/Page/Page";
import ManagedAppsPage from "../../features/ManagedApps/pages/ManagedAppsPage/ManagedAppsPage";
import ManagedAppPage from "../../features/ManagedApps/pages/ManagedAppPage/ManagedAppPage";
import SelectManagedAppPage from "../../features/ManagedApps/pages/SelectManagedAppPage/SelectManagedAppPage";
import CreateManagedAppPage from "../../features/ManagedApps/pages/CreateManagedAppPage/CreateManagedAppPage";
import BillingPage from "../../features/Billing/pages/BillingPage/BillingPage";
import InvoicePage from "../../features/Billing/pages/InvoicePage/InvoicePage";
import VdcsPage from "../../features/Vdcs/pages/VdcsPage/VdcsPage";
import VdcPage from "../../features/Vdcs/pages/VdcPage/VdcPage";
import EditVdcPage from "../../features/Vdcs/pages/EditVdcPage/EditVdcPage";
import CreateVdcPage from "../../features/Vdcs/pages/CreateVdcPage/CreateVdcPage";
import NotFound from "../pages/NotFound/NotFound";
import AppContainer from "../components/AppContainer/AppContainer";
import ProtectedRoute from "./ProtectedRoute";
import DomainsPage from "../../features/Domains/pages/DomainsPage/DomainsPage";
import RegistrantPage from "../../features/Domains/pages/RegistrantDetailPage/RegistrantDetailPage";
import DomainDetailPage from "features/Domains/pages/DomainDetailPage/DomainDetailPage";
import { Features } from "app/constants/Features";

const AppRoutes: FC = () => {
    const location = useLocation()

    const key = useMemo(() => {
        let paths = Object
            .values(RoutingDefinition)
            .filter(r => matchPath(r.path, location.pathname));

        let longestPath = paths.sort((a, b) => b.path.length - a.path.length)[0]?.path

        console.log(paths, longestPath)

        return longestPath
    }, [location])

    return (
        <AppContainer>
            <AnimatePresence mode="wait">
                <ReactRoutes
                    location={location}
                    key={key}
                >
                    {/* Public routes */}
                    <Route
                        path={RoutingDefinition.oauthRedirect.path}
                        element={<OAuthRedirect />}
                    />

                    {/* Restricted routes */}
                    <Route
                        element={<ProtectedRoute />}
                    >
                        <Route
                            path="/"
                            element={<Navigate to="/managedApps" replace />}
                        />

                        {/* The routes below will be rendered inside the standard app-layout. */}
                        <Route
                            path={RoutingDefinition.managedApps.path}
                            element={Page(ManagedAppsPage)}
                        />

                        <Route
                            path={RoutingDefinition.managedApp.path}
                            element={Page(ManagedAppPage)}
                        />

                        <Route
                            path={RoutingDefinition.selectManagedApp.path}
                            element={Page(SelectManagedAppPage)}
                        />

                        <Route
                            path={RoutingDefinition.createManagedApp.path}
                            element={Page(CreateManagedAppPage)}
                        />

                        <Route
                            path={RoutingDefinition.domainNames.path}
                            element={Page(DomainsPage)}
                        />

                        <Route
                            path={RoutingDefinition.domainName.path}
                            element={Page(DomainDetailPage)}
                        />

                        <Route
                            path={RoutingDefinition.registrant.path}
                            element={Page(RegistrantPage)}
                        />

                        <Route
                            path={RoutingDefinition.billing.path}
                            element={Page(BillingPage)}
                        />

                        <Route
                            path={RoutingDefinition.invoice.path}
                            element={Page(InvoicePage)}
                        />

                        <Route
                            path={RoutingDefinition.vdcs.path}
                            element={Page(VdcsPage, Features.PrivateCloud
                            )}
                        />

                        <Route
                            path={RoutingDefinition.vdc.path}
                            element={
                                Page(
                                    VdcPage,
                                    Features.PrivateCloud
                                )
                            }
                        />

                        <Route
                            path={RoutingDefinition.editVdc.path}
                            element={Page(EditVdcPage)}
                        />

                        <Route
                            path={RoutingDefinition.createVdc.path}
                            element={Page(CreateVdcPage)}
                        />

                        <Route
                            path="*"
                            element={Page(NotFound)}
                        />
                    </Route>
                </ReactRoutes>
            </AnimatePresence>
        </AppContainer>
    )
}

export default AppRoutes