import { useIsFeatureEnabledQuery } from "app/redux/featuresApi"
import { IRouteDefinition } from "app/routes/RoutingDefinition"
import AppLink from "components/AppLink/AppLink"
import MaterialIcon from "components/MaterialIcon/MaterialIcon"
import MaterialSymbol from "components/MaterialSymbol/MaterialSymbol"
import { FC } from "react"

interface IProps {
    def: IRouteDefinition
}

export const SidebarLink: FC<IProps> = ({ def }) => {
    const baseClassName = "sidebar";
    
    const { isError } = useIsFeatureEnabledQuery(def.feature ?? "", { skip: !def.feature })

    return (
        <div className={`${baseClassName}__route-container`} key={`route__${def.path}`}>
            <AppLink to={def.navigationLink ? def.navigationLink : def.path}>
                <div
                    className={`${baseClassName}__route`}
                >
                    <MaterialSymbol
                        name={def.iconName!}
                        className={`${baseClassName}__route-icon`}
                    />

                    <p className={`${baseClassName}__route-name`}>{def.name}</p>
                </div>
            </AppLink>
        </div>
    )
}