import React, { FC, ReactNode } from "react";
import { motion } from "framer-motion";
import { useIsFeatureEnabledQuery } from "app/redux/featuresApi";
import NotFound from "app/pages/NotFound/NotFound";

const Page = (Component: FC, feature?: string) => {
    const { isError } = useIsFeatureEnabledQuery(feature ?? "", { skip: !feature })

    return (
        <motion.div
            key="modal"
            className="page"
            initial={{ opacity: 0, translateX: -10 }}
            animate={{ opacity: 1, translateX: 0 }}
            exit={{ opacity: 0, translateX: -10 }}
        >
            {isError
                ? <NotFound />
                : <Component />}
        </motion.div >
    )
}


export default Page